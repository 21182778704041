<template>
  <div>
    <list-template
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        has-back
        @onChangePage="changeCurrentPage"
    ></list-template>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"checking-in","meta":{"title":"考勤异常记录","style":1}}},
  data(){
    return {
      // 表格配置
      tableConfig: [
        {
          label: '打卡状态',
          prop: 'punch_in_status',
          render:(row)=>{
            let str;
            if(row.punch_in_status == 0) str = '未打卡'
            if(row.punch_in_status == 1) str = '正常'
            if(row.punch_in_status == 2) str = '迟到'
            return str
          }
        },
        {label: '迟到时间', prop: 'be_late_time'},
        {label: '日期（星期）', prop: 'check_date'},
        {label: '课节数', prop: 'section'},
        {label: '学科', prop: 'subject_name'},
        {label: '教学班名称', prop: 'class_name'},
        {label: '教学班老师', prop: 'teacher_name'},
        {label: '上课教室', prop: 'classroom_name'},
      ],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null,
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },

    getData(){
      let params = {
        student_id: this.id,
        page:this.page,
        pageSize: 20
      }
      this.$_axios.post("attendance/one-stu-atend-list",params).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },
  }
}
</script>

<style scoped>

</style>
